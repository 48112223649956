.loader_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  color: #fff;
  font-size: 2em;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
  }

  @keyframes load6 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes round {
    0% {
      width: 60px;
      height: 60px;
      opacity: 0.6;
    }
    100% {
      width: 100px;
      height: 100px;
      opacity: 0;
    }
  }
}